import { navigate } from "gatsby-link";
import React from "react";
import YouTube from "react-youtube";

// markup
const IndexPage = () => {
  return (
    <div>
      {/* <PrivateRoute component={ VertragList }/> */}
      {/* <VertragList/> */}
      <h2>Willkommen auf rap-tool.de</h2>
      Ihre Lösung für die Berechnung von Rechnungsabgrenzungsposten
      <div className="text-center mt-4">
        <button className="button button-primary" onClick={() => navigate("/interaktiv")}>
          Einfach ausprobieren
        </button>

        <YouTube
          videoId={"znY_zEZ-rs8"}
          className="inline-block" // defaults -> null
          containerClassName={"text-center mt-8"} // defaults -> ''
          title={"Einführungsvideo"} // defaults -> null
        />
      </div>
    </div>
  );
};

export default IndexPage;
